<template>
  <div>
    <a-row style="height: 800px">
      <a-col :span="11" style="padding-right: 10px; height: 100%">
        <a-row class="iotplt-card-box" style="height: 100px">
          <h1>{{ product.name }}</h1>
          <p>{{ product.user_business_type }}</p>
        </a-row>

        <a-row class="iotplt-card-box" style="margin-top: 10px; height: 690px">
          <a-descriptions
            title="套餐信息"
            :column="1"
            class="iotplt-descriptions"
          >
            <a-descriptions-item label="运营商种类">
              {{ product.carrier_type }}
            </a-descriptions-item>

            <a-descriptions-item label="套餐周期">
              {{ product.service_cycle }}
            </a-descriptions-item>

            <a-descriptions-item label="套餐容量">
              {{ product.package_capacity }}
            </a-descriptions-item>

            <a-descriptions-item label="语音">
              {{ product.voice_capacity }}
            </a-descriptions-item>
          </a-descriptions>

          <a-descriptions title="套餐价格" />

          <a-row type="flex" :gutter="24">
            <a-col flex="142px">
              <div>采购周期数量</div>
            </a-col>
            <a-col flex="300px">
              套餐价格(元)
              <a-popover trigger="hover">
                <template slot="content">
                  <p>根据单卡采购周期数量，使用对应的套餐价格进行计费，在线支付费用；</p>
                  <p>如果使用量超额，将收取超额费用</p>
                </template>
                <a-space>
                  <a-icon type="question-circle" />
                </a-space>
              </a-popover>
            </a-col>
          </a-row>

          <a-row
            type="flex"
            :gutter="24"
            v-for="favorablePrice in product.agents_product_favorable_prices"
            :key="favorablePrice.cycles"
          >
            <a-col flex="142px">
              {{ favorablePrice.cycles }}
            </a-col>
            <a-col flex="300px">
              {{ favorablePrice.distributor_price }}
            </a-col>
          </a-row>

          <a-row
            type="flex"
            :gutter="24"
            :style="{ marginTop: '10px', marginBottom: '16px' }"
          >
            <a-col flex="142px">
              超额资费
            </a-col>
            <a-col flex="300px">
              {{ product.excess_distributor_price }}
            </a-col>
          </a-row>

          <a-descriptions
            title="卡板价格"
            :column="1"
          />

          <a-descriptions
            :column="1"
            class="iotplt-descriptions iotplt-descriptions-max-9 card-price-wrapper"
          >
            <a-descriptions-item
              v-for="cardType in cardTypes"
              :label="cardType.name"
              :key="cardType.id"
            >
              {{ cardType.price }}
            </a-descriptions-item>
          </a-descriptions>
        </a-row>
      </a-col>

      <a-col :span="13" style="height: 100%">
        <a-row class="iotplt-card-box" style="height: 100%">
          <a-col>
            <a-descriptions title="采购信息" :column="1" />
            <a-form
              class="iotplt-compact-form"
              :form="form"
              @submit="handleSubmit"
            >
              <a-form-item
                label="卡片材质"
                :style="{ marginBottom: 0 }"
              >
                <a-spin :spinning="loadingCardTypeOptions">
                  <a-radio-group
                    button-style="solid"
                    @change="handleCardTypeChange"
                    v-decorator="['card_type_id',{
                      rules: [
                        { required: true, message: '请选择卡片材质' }
                      ]
                    }]"
                  >
                    <a-radio-button
                      class="card-select-box card-type-box"
                      v-for="cardType in cardTypes"
                      :key="cardType.id"
                      :value="cardType.id"
                    >
                      {{ cardType.name }}
                    </a-radio-button>
                  </a-radio-group>
                </a-spin>
              </a-form-item>

              <a-form-item
                label="卡片尺寸"
                :style="{ marginBottom: 0, minHeight: '91px' }"
              >
                <a-spin :spinning="loadingCardShapeOptions">
                  <a-radio-group
                    button-style="solid"
                    v-decorator="['card_shape_id',{
                      rules: [
                        { required: true, message: '请选择卡片尺寸' }
                      ]
                    }]"
                  >
                    <a-radio-button
                      class="card-select-box card-shape-box"
                      v-for="cardShape in cardShapeOptions"
                      :key="cardShape.id"
                      :value="cardShape.id"
                    >
                      {{ cardShape.name }}
                    </a-radio-button>
                  </a-radio-group>
                </a-spin>
              </a-form-item>

              <a-form-item
                label="服务周期"
                required="required"
              >
                <a-space>
                  <a-input-number
                    :step="1"
                    style="width: 120px"
                    :formatter="parseServicePeriodInputValue"
                    :parser="parseServicePeriodInputValue"
                    :disabled="isMerchantSales"
                    @change="handleServicePeriodChange"
                    v-decorator="['service_period', {
                      initialValue: minFavorablePriceCycle,
                      rules: [
                        { validator: checkServicePeriod }
                      ]
                    }]"
                  />
                  <span>个周期</span>
                </a-space>
              </a-form-item>

              <a-form-item label="采购卡数" required="required">
                <a-space>
                  <a-input-number
                    :min="1"
                    :max="cardCountMaxValue"
                    :step="1"
                    :precision="0"
                    style="width: 120px"
                    :formatter="parseCardCountInputValue"
                    :parser="parseCardCountInputValue"
                    @change="handleCardCountChange"
                    v-decorator="['card_count', {
                      rules: [
                        { required: true, message: '请输入采购卡数' }
                      ]
                    }]"
                  />
                  <span>张</span>
                </a-space>
              </a-form-item>

              <a-form-item label="备注">
                <a-textarea
                  v-decorator="['remark', {
                    rules: [
                      { max: 200, message: '最多200个字符' },
                    ]
                  }]"
                  :auto-size="{ minRows: 2, maxRows: 5 }"
                />
              </a-form-item>

              <a-descriptions
                :column="1"
                class="iotplt-descriptions iotplt-descriptions-max-6"
              >
                <a-descriptions-item label="套餐费用(元)">
                  {{ productFeeDisplay }}
                </a-descriptions-item>

                <a-descriptions-item label="卡板费用(元)">
                  {{ cardFeeDisplay }}
                </a-descriptions-item>

                <a-descriptions-item label="小计(元)">
                  {{ totalFeeDisplay }}
                </a-descriptions-item>
              </a-descriptions>

              <a-form-item>
                <a-button
                  type="primary"
                  html-type="submit"
                  :loading="submitting"
                >
                  加入购物车
                </a-button>
              </a-form-item>

            </a-form>
          </a-col>
        </a-row>
      </a-col>
    </a-row>
  </div>
</template>

<script>
import { findGoodsInfo } from '@/api/agents_product'
import { findCardShapeOptions } from '@/api/common_options'
import { createAgentPurchaseItem, calculateAgentPurchaseItemFee } from '@/api/agent_purchase_item'
import { formatFloat } from '@/utils/filter'

export default {
  name: 'NewAgentPurchaseItem',
  data() {
    this.calculateProductFee = this.$lodash.debounce(this.calculateProductFee, 400)
    return {
      product: {},
      cardTypes: [],
      cardShapeOptions: [],
      servicePeriodMaxValue: 1000000,
      cardCountMaxValue: 10000000,
      loadingCardTypeOptions: false,
      loadingCardShapeOptions: false,
      isMerchantSales: false,
      productFeeDisplay: '', // 套餐费用
      cardFeeDisplay: '', // 卡板费用
      totalFeeDisplay: '', // 总费用
      disabledSubmit: false,
      submitting: false,
      minFavorablePriceCycle: 1,
      maxFavorablePriceCycle: 1,
      form: this.$form.createForm(this, { name: 'agent_purchase_item' })
    }
  },
  computed: {
    goodsId() {
      return parseInt(this.$route.query.goods_id)
    }
  },
  created() {
    this.fetchGoodsInfoData()
  },
  methods: {
    parseServicePeriodInputValue(val) {
      if (!val) {
        return ''
      }

      if (val >= this.minFavorablePriceCycle) {
        return parseInt(val)
      }

      return val
    },

    parseCardCountInputValue(val) {
      if (!val) {
        return ''
      }

      if (val < 1) {
        val = 1
      } else if (val > this.cardCountMaxValue) {
        val = this.cardCountMaxValue
      }
      return parseInt(val)
    },

    calculateProductFee() {
      if (this.goodsId !== undefined) {
        if (this.form.getFieldValue('card_type_id') === undefined ||
          this.form.getFieldValue('card_count') === undefined ||
          this.form.getFieldValue('service_period') === undefined) {
          return
        }
        this.form.validateFields(['service_period', 'card_count'], { force: true }, (err, values) => {
          if (!err) {
            const data = {
              agent_id: this.$store.getters.userAgentId,
              goods_id: this.goodsId,
              service_period: values.service_period,
              card_count: values.card_count,
              card_type_id: this.form.getFieldValue('card_type_id')
            }
            calculateAgentPurchaseItemFee(Object.assign({}, data)).then((res) => {
              if (res.code === 0) {
                const servicePeriod = res.data.service_period
                const cardCount = res.data.card_count

                // 返回结果和当前填的服务周期、材质、卡数不一致, 则不设置值
                if (servicePeriod !== this.form.getFieldValue('service_period') ||
                  cardCount !== this.form.getFieldValue('card_count') ||
                  res.data.card_type_id !== this.form.getFieldValue('card_type_id')) {
                  return
                }

                this.productFeeDisplay = `(${formatFloat(res.data.favorable_prices_distributor_price)} / ${res.data.favorable_prices_cycle}) * ${servicePeriod} * ${cardCount} = ${formatFloat(res.data.product_fee)}`
                this.cardFeeDisplay = `${formatFloat(res.data.card_price)} * ${cardCount} = ${formatFloat(res.data.card_fee)}`
                this.totalFeeDisplay = `${formatFloat(res.data.product_fee)} + ${formatFloat(res.data.card_fee)} = ${formatFloat(res.data.total_fee)}`
              }
            })
          }
        })
      }
    },

    fetchGoodsInfoData() {
      this.loadingCardTypeOptions = true
      findGoodsInfo(this.goodsId).then((res) => {
        if (res.code === 0) {
          this.product = res.data

          const agentsProductFavorablePrices = this.product.agents_product_favorable_prices
          this.minFavorablePriceCycle = agentsProductFavorablePrices[0].cycles
          this.maxFavorablePriceCycle = agentsProductFavorablePrices[(agentsProductFavorablePrices).length - 1].cycles

          this.cardTypes = res.data.card_types
          this.loadingCardTypeOptions = false
          const cardTypeId = this.cardTypes[0] ? this.cardTypes[0].id : undefined
          this.form.setFieldsValue({
            card_type_id: cardTypeId
          })

          if (res.data.user_business_type_slug === 'merchant_sales') {
            this.isMerchantSales = true
          }

          this.fetchCardShapesData(cardTypeId)
        }
      })
    },

    // 加载卡片尺寸
    fetchCardShapesData(cardTypeId) {
      if (cardTypeId) {
        findCardShapeOptions(cardTypeId).then((res) => {
          if (res.code === 0) {
            this.loadingCardShapeOptions = false
            this.cardShapeOptions = res.data
            this.form.setFieldsValue({
              card_shape_id: this.cardShapeOptions[0].id
            })
          }
        })
      }
    },

    clearFeeDisplay() {
      this.productFeeDisplay = ''
      this.cardFeeDisplay = ''
      this.totalFeeDisplay = ''
    },

    // 卡片材质变更，加载卡片尺寸
    handleCardTypeChange(e) {
      this.clearFeeDisplay()
      const cardTypeId = e.target.value
      this.loadingCardShapeOptions = true
      // 清空选项
      this.cardShapeOptions = []
      // 清空选项选中的值
      this.form.setFieldsValue({
        card_shape_id: undefined
      })

      if (cardTypeId) {
        this.fetchCardShapesData(cardTypeId)
        this.calculateProductFee()
      }
    },

    checkServicePeriod(rule, value, callback) {
      if (!value && value !== 0) {
        // eslint-disable-next-line standard/no-callback-literal
        callback('请输入服务周期数')
        return
      }

      if (value > this.servicePeriodMaxValue) {
        // eslint-disable-next-line standard/no-callback-literal
        callback('服务周期数不大于1000000')
        return
      }

      if (value < this.minFavorablePriceCycle ||
        (value > this.maxFavorablePriceCycle && value % this.maxFavorablePriceCycle !== 0)) {
        // eslint-disable-next-line standard/no-callback-literal
        callback(`服务周期需要不小于${this.minFavorablePriceCycle}，或者为${this.maxFavorablePriceCycle}的倍数`)
        return
      }

      callback()
    },

    // 处理改变服务周期值
    handleServicePeriodChange(servicePeriod) {
      this.clearFeeDisplay()
      if (servicePeriod > 0) {
        this.calculateProductFee()
      }
    },

    // 处理改变采购卡数值
    handleCardCountChange(cardCount) {
      this.clearFeeDisplay()
      if (cardCount > 0) {
        this.calculateProductFee()
      }
    },

    handleSubmit(e) {
      e.preventDefault()
      if (this.submitting) {
        this.$warning({
          title: '请勿重复提交',
          content: ''
        })
        return
      }

      this.form.validateFields((err, values) => {
        if (!err) {
          this.submitting = true
          createAgentPurchaseItem(Object.assign(
            { agent_id: this.$store.getters.userAgentId, goods_id: this.goodsId },
            values)).then((res) => {
            if (res.code === 0) {
              this.$router.push({ name: 'cart' })
            } else {
              this.submitting = false
            }
          })
        }
      })
    }
  }

}
</script>

<style lang="less" scoped>
.card-price-wrapper{
  max-height: 250px;
  overflow: auto;

  &::-webkit-scrollbar {
    width: 2px;
    height: 2px;
  }

  &::-webkit-scrollbar-track {
    background: #fff;
    border-radius: 2px;
  }

  &::-webkit-scrollbar-thumb {
    background: #ccc;
    border-radius: 10px;
  }
}

.card-select-box {
  margin: 0 10px 5px 0;
  border-radius: 0 !important;
  border: 1px solid #d9d9d9;
  text-align: center;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  &.card-type-box {
    min-width: 115px;
    max-width: 240px;
  }

  &.card-shape-box {
    min-width: 105px;
    max-width: 220px;
  }
}

</style>
